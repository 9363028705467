import CssBaseline from '@mui/material/CssBaseline';
import { Head } from "@inertiajs/inertia-react";
import { Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { ReactNode } from 'react';

const ErrorPageLayout = ({ children }: { children: ReactNode }) => {
    return <>
      <Head title={"Error"} />
      <CssBaseline />
      <main>
        <Box sx={{ backgroundColor: 'grey.200' }}>
          <Header />
          {children}
          <Footer />
        </Box>
      </main>
    </>
};

export default (page: ReactNode) => <ErrorPageLayout>{page}</ErrorPageLayout>;
