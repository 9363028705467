import React, { ReactNode } from 'react';
import { createRoot } from "react-dom/client";
import { createInertiaApp, ReactComponent } from '@inertiajs/inertia-react';
import axios from "axios";
import Layout from "../layout/Layout";
import theme from '../config/theme';
import { ThemeProvider } from '@mui/material/styles';
import TimeProvider from '../context/TimeProvider';
import EnergyOutputProvider from '../context/EnergyOutputProvider';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Honeybadger from '@honeybadger-io/js';
import { HONEYBADGER_API_KEY } from '../config/services';
import ErrorBoundary from '../components/ErrorBoundary';
import GaTracker from '../utils/GaTracker';

// TODO: Now that we have dayjs, we should
// use it in places where we're currently
// doing timezone math the long way.
dayjs.extend(utc)
dayjs.extend(timezone)

Honeybadger.configure({
  apiKey: HONEYBADGER_API_KEY,
  environment: import.meta.env.VITE_RUBY_MODE,
  revision: 'main',
});

type ReactComponentImport = {
  default: ReactComponent & { layout: (page: ReactNode) => ReactNode };
};

const pages = import.meta.glob('../Pages/*/index.tsx') as Record<
  string,
  () => Promise<ReactComponentImport>
>;

document.addEventListener("DOMContentLoaded", () => {
  GaTracker.initialize();

  createInertiaApp({
    resolve: async (name) => {
      const pageImporter = pages[`../Pages/${name}/index.tsx`];
      const page = (await pageImporter!())!.default;
      page!.layout = page!.layout || Layout;
      return page;
    },
    setup({ el, App, props }) {
      createRoot(el).render(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              <TimeProvider>
                <EnergyOutputProvider>
                  <App {...props} />
                </EnergyOutputProvider>
              </TimeProvider>
            </ThemeProvider>
          </ErrorBoundary>
        </LocalizationProvider>
      );
    },
  });

  const csrfToken = document.querySelector("meta[name=csrf-token]")?.getAttribute('content');
  if (csrfToken) {
    axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  }
});
