import { Box, Stack, Divider, Link } from '@mui/material';
import Section from '../components/Section';

const Header = () => {
  return (
    <Section sx={{
      padding: '16px 5%',
    }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Box sx={{ padding: '0 .5em', maxWidth: '104px' }}>
          <Link href="https://www.tep.com"><img src="/tep-logo.png" alt="TEP" style={{ width: '100%' }} /></Link>
        </Box>
        <Box><img src="/gogreen-now.svg" style={{ width: '100%' }} /></Box>
      </Stack>
    </Section>
  );
};

export default Header;
