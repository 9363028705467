import ErrorPageLayout from "../../layout/ErrorPageLayout";
import { Box, Typography, Link } from '@mui/material';
import Section from "../../components/Section";

type Props = { error_code?: string | number, error_message?: string };

function ErrorPage({ error_code, error_message }: Props) {
  return (
    <Box>
      <Section>
        <Typography variant="h1" mb={3}>
          Error
        </Typography>

        { error_message
          ? <Box mb={2} sx={{ fontSize: '28px' }}>
            {error_code} &mdash; {error_message}
          </Box>
          : <Box mb={2} sx={{ fontSize: '28px' }}>
            An error has prevented the action requested.
          </Box>
        }
        <Box mb={2} sx={{ fontSize: '28px' }}>
          Please return to the <Link href="/">home page</Link>.
        </Box>
      </Section>
    </Box>
  );
}

ErrorPage.layout = ErrorPageLayout;

export default ErrorPage;
