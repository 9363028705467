import { useState, useEffect } from 'react';
import { EMAPS_API_ZONE } from '../config/services';
import makeGetHttp from '../utils/getHttp';
import debug from '../debug';

export type Breakdown = {
  'nuclear': number | null;
  'geothermal': number | null;
  'biomass': number | null;
  'coal': number | null;
  'wind': number | null;
  'solar': number | null;
  'hydro': number | null;
  'gas': number | null;
  'oil': number | null;
  'unknown': number | null;
  'hydro discharge': number | null;
  'battery discharge': number | null;
};


export type PowerBreakdownPastRange = {
  data: Array<{
    datetime: string;
    powerProductionBreakdown: Breakdown;
    powerConsumptionBreakdown: Breakdown;
  }>;
};

const get = makeGetHttp('/emaps', {});

type Props = {
  currentHour: Date;
};

/**
 * Get the data we need from eMaps, treating
 * `currentHour` as the current time.
 */
const useEMaps = ({ currentHour }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<typeof Error | null>(null);
  const [pastRangeJson, setPastRangeJson] = useState<PowerBreakdownPastRange>();

  const zone = debug.zone || EMAPS_API_ZONE;

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (error) {
      setError(null);
    }
    setPastRangeJson(undefined);

    const start = new Date(currentHour);
    start.setUTCHours(start.getUTCHours() - (7 * 24));
    const end = new Date(currentHour);
    end.setUTCHours(end.getUTCHours() + 24);

    Promise.resolve()
      .then(() =>
        get<PowerBreakdownPastRange>('/past-range', {
          zone,
          start: start.toISOString(),
          end: end > new Date() // DO NOT go to the past's future. 😳
            ? new Date().toISOString()
            : end.toISOString(),
        })
          .then((json) => setPastRangeJson(json))
      )
      .catch((error) => {
        setError(error);
        console?.error(error);
      })
      .finally(() => setLoading(false));

  }, [currentHour.toISOString()]);

  return {
    loading,
    error,
    pastRange: pastRangeJson,
  };
};

export default useEMaps;
