import ErrorPage from "../Pages/ErrorPage";
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ReactNode } from 'react';
import Honeybadger from '@honeybadger-io/js';

// TypeScript says `ErrorPage` cannot be used
// for `FallbackComponent`, but it doesn't mind
// if we pass this wrapper instead.
const ErrorFallback = () => {
  return <ErrorPage error_message="Error unknown" />;
};

const report = (error: Error) => {
  // Sentry.captureException(error);
  Honeybadger.notify(error);
};

export default function ErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={report}>
      {children}
    </ReactErrorBoundary>
  );
}
