import { Box, Stack, Divider, Typography, Link } from "@mui/material";
import Section from "../components/Section";

const Footer = () => {
  return (
    <Section
      sx={{
        backgroundColor: "white.100",
        color: 'grey.400',
      }}
    >
      <Box
        display="grid"
        gap="1rem"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems={{ xs: 'left', lg: 'center' }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Box sx={{ maxWidth: '104px' }}><img src="/tep-logo.png" alt="TEP" style={{ width: '100%' }} /></Box>
          <Stack>
            <Box>
              &copy; 2023 Tucson Electric Power. All rights reserved.
            </Box>
            <Box sx={{ display: { sx: 'block', lg: 'none' } }}>
              <Link href="https://www.bellawatt.com">
                Powered by Bellawatt
              </Link>
            </Box>
          </Stack>
        </Stack>
        <Stack sx={{ display: { lg: 'flex', xs: 'none' } }} textAlign={{ xs: 'center', lg: 'right' }} alignItems="center" direction="row">
          <Typography width="100%" >
            <Link
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              href="https://www.bellawatt.com"
            >
              Powered by Bellawatt
            </Link>
          </Typography>
        </Stack>
      </Box>
    </Section>
  );
};

export default Footer;
