import { PowerBreakdownPastRange } from '../hooks/useEMaps';
import { OpenMeteoForecast } from '../hooks/useOpenMeteo';
import log from './log';
import forecastSolarGeneration from '../utils/forecastSolarGeneration';
import forecastWindGeneration from '../utils/forecastWindGeneration';

/**
 * Use the API results to build an array of
 * output points in the format we prefer.
 * This is also a good time to pull out
 * thresholds and other meta-data around
 * historical output points.
 */
const buildHistorical = (emapsPastRange: PowerBreakdownPastRange) => {
  const hours = emapsPastRange.data;
  const historicalOutput = hours.map((hour) => {
    const { wind, solar } = hour.powerProductionBreakdown;
    const renewable = (wind || 0) + (solar || 0);
    return {
      datetime: hour.datetime,
      output: renewable,
    };
  });

  // Thresholds defined by business logic
  // https://docs.google.com/document/d/1gcUCwhOJOJdLSSVCfeRG7nNL-mNeONmeGWlL3lCWjp4/edit
  const maxOutput = Math.max(
    ...historicalOutput.map(({ output }) => output)
  );
  const highThreshold = maxOutput * .8;
  const lowThreshold = maxOutput * .65;
  return {
    historicalOutput,
    highThreshold,
    lowThreshold,
  };
};

const buildForecast = (openMeteoForecast: OpenMeteoForecast) => {
  const {
    time,
    direct_radiation,
    windspeed_10m,
    winddirection_10m,
  } = openMeteoForecast.hourly;
  return time.map((t, i) => {
    const { datetime, solarGen } = forecastSolarGeneration(t, direct_radiation[i]);
    const windGen = forecastWindGeneration(windspeed_10m[i], winddirection_10m[i]);
    return {
      datetime,
      output: solarGen + windGen,
    };
  });
};


type Props = {
  emapsPastRange: PowerBreakdownPastRange;
  openMeteoForecast: OpenMeteoForecast;
  currentHour: Date;
};

const calculateEnergyOutput = ({
  emapsPastRange,
  openMeteoForecast,
  currentHour,
}: Props) => {
  const {
    historicalOutput,
    highThreshold,
    lowThreshold,
  } = buildHistorical(emapsPastRange);

  const forecastOutput = buildForecast(openMeteoForecast);

  const currentHourString = currentHour.toISOString();
  const realTime = historicalOutput.find(({ datetime }) => datetime === currentHourString);

  log('Compiled results:', {
    realTime,
    highThreshold,
    lowThreshold,
    historicalOutput,
    forecastOutput,
  });

  return {
    realTime,
    highThreshold,
    lowThreshold,
    historicalOutput,
    forecastOutput,
  };
};

export default calculateEnergyOutput;
