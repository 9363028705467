import { useState, useEffect } from 'react';
import log from '../utils/log';
import useEMaps from './useEMaps';

const SOME_PAST_RANGE_WE_SAW_ONE_TIME = {
  "zone": "US-SW-TEPC",
  "data": [
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-29T17:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:21.509Z",
      "createdAt": "2023-09-26T17:48:12.664Z",
      "powerConsumptionBreakdown": {
        "nuclear": 123,
        "geothermal": 0,
        "biomass": 0,
        "coal": 271,
        "wind": 60,
        "solar": 329,
        "hydro": 12,
        "gas": 980,
        "oil": 0,
        "unknown": 2,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 282,
        "wind": 43,
        "solar": 335,
        "hydro": null,
        "gas": 968,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 223,
        "US-SW-AZPS": 17,
        "US-SW-WALC": 167
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 239,
        "US-SW-PNM": 20,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 30,
      "renewablePercentage": 23,
      "powerConsumptionTotal": 1776,
      "powerProductionTotal": 1628,
      "powerImportTotal": 407,
      "powerExportTotal": 259,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-29T18:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:26.188Z",
      "createdAt": "2023-09-26T18:46:01.164Z",
      "powerConsumptionBreakdown": {
        "nuclear": 130,
        "geothermal": 0,
        "biomass": 0,
        "coal": 270,
        "wind": 101,
        "solar": 350,
        "hydro": 12,
        "gas": 1000,
        "oil": 0,
        "unknown": 2,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 268,
        "wind": 70,
        "solar": 333,
        "hydro": null,
        "gas": 958,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 33,
        "US-SW-SRP": 225,
        "US-SW-AZPS": 44,
        "US-SW-WALC": 163
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 230,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 32,
      "renewablePercentage": 25,
      "powerConsumptionTotal": 1864,
      "powerProductionTotal": 1629,
      "powerImportTotal": 465,
      "powerExportTotal": 230,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-29T19:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:04.941Z",
      "createdAt": "2023-09-26T19:46:32.749Z",
      "powerConsumptionBreakdown": {
        "nuclear": 129,
        "geothermal": 0,
        "biomass": 0,
        "coal": 285,
        "wind": 144,
        "solar": 372,
        "hydro": 14,
        "gas": 1088,
        "oil": 0,
        "unknown": 2,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 268,
        "wind": 75,
        "solar": 330,
        "hydro": null,
        "gas": 1018,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 102,
        "US-SW-SRP": 191,
        "US-SW-AZPS": 97,
        "US-SW-WALC": 179
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 225,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 32,
      "renewablePercentage": 26,
      "powerConsumptionTotal": 2035,
      "powerProductionTotal": 1691,
      "powerImportTotal": 569,
      "powerExportTotal": 225,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-29T20:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:04.941Z",
      "createdAt": "2023-09-26T20:47:32.963Z",
      "powerConsumptionBreakdown": {
        "nuclear": 173,
        "geothermal": 1,
        "biomass": 0,
        "coal": 294,
        "wind": 172,
        "solar": 395,
        "hydro": 18,
        "gas": 1117,
        "oil": 0,
        "unknown": 15,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 268,
        "wind": 93,
        "solar": 331,
        "hydro": null,
        "gas": 1016,
        "oil": null,
        "unknown": 13,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 122,
        "US-SW-SRP": 262,
        "US-SW-AZPS": 146,
        "US-SW-WALC": 198
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 263,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 35,
      "renewablePercentage": 27,
      "powerConsumptionTotal": 2186,
      "powerProductionTotal": 1721,
      "powerImportTotal": 728,
      "powerExportTotal": 263,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-29T21:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:04.941Z",
      "createdAt": "2023-09-26T21:44:25.468Z",
      "powerConsumptionBreakdown": {
        "nuclear": 170,
        "geothermal": 1,
        "biomass": 0,
        "coal": 292,
        "wind": 188,
        "solar": 392,
        "hydro": 23,
        "gas": 1205,
        "oil": 0,
        "unknown": 29,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 268,
        "wind": 97,
        "solar": 328,
        "hydro": null,
        "gas": 1122,
        "oil": null,
        "unknown": 30,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 154,
        "US-SW-SRP": 259,
        "US-SW-AZPS": 178,
        "US-SW-WALC": 187
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 323,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 34,
      "renewablePercentage": 26,
      "powerConsumptionTotal": 2300,
      "powerProductionTotal": 1845,
      "powerImportTotal": 778,
      "powerExportTotal": 323,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-29T22:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:26.188Z",
      "createdAt": "2023-09-26T22:43:49.646Z",
      "powerConsumptionBreakdown": {
        "nuclear": 149,
        "geothermal": 1,
        "biomass": 0,
        "coal": 295,
        "wind": 225,
        "solar": 389,
        "hydro": 28,
        "gas": 1320,
        "oil": 0,
        "unknown": 18,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 268,
        "wind": 107,
        "solar": 319,
        "hydro": null,
        "gas": 1246,
        "oil": null,
        "unknown": 18,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 194,
        "US-SW-SRP": 208,
        "US-SW-AZPS": 202,
        "US-SW-WALC": 184
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 321,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 33,
      "renewablePercentage": 27,
      "powerConsumptionTotal": 2425,
      "powerProductionTotal": 1958,
      "powerImportTotal": 788,
      "powerExportTotal": 321,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-29T23:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:26.188Z",
      "createdAt": "2023-09-26T23:46:06.715Z",
      "powerConsumptionBreakdown": {
        "nuclear": 139,
        "geothermal": 0,
        "biomass": 0,
        "coal": 324,
        "wind": 216,
        "solar": 317,
        "hydro": 37,
        "gas": 1483,
        "oil": 0,
        "unknown": 3,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 299,
        "wind": 128,
        "solar": 263,
        "hydro": null,
        "gas": 1357,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 137,
        "US-SW-SRP": 205,
        "US-SW-AZPS": 189,
        "US-SW-WALC": 186
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 244,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 28,
      "renewablePercentage": 23,
      "powerConsumptionTotal": 2520,
      "powerProductionTotal": 2047,
      "powerImportTotal": 717,
      "powerExportTotal": 244,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T00:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:26.188Z",
      "createdAt": "2023-09-27T00:45:31.872Z",
      "powerConsumptionBreakdown": {
        "nuclear": 73,
        "geothermal": 0,
        "biomass": 0,
        "coal": 415,
        "wind": 170,
        "solar": 109,
        "hydro": 75,
        "gas": 1673,
        "oil": 0,
        "unknown": 6,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 393,
        "wind": 122,
        "solar": 93,
        "hydro": null,
        "gas": 1508,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 39,
        "US-SW-SRP": 63,
        "US-SW-AZPS": 175,
        "US-SW-WALC": 245
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 116,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 17,
      "renewablePercentage": 14,
      "powerConsumptionTotal": 2522,
      "powerProductionTotal": 2116,
      "powerImportTotal": 522,
      "powerExportTotal": 116,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T01:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:26.188Z",
      "createdAt": "2023-09-27T01:46:39.080Z",
      "powerConsumptionBreakdown": {
        "nuclear": 48,
        "geothermal": 0,
        "biomass": 0,
        "coal": 523,
        "wind": 147,
        "solar": 11,
        "hydro": 82,
        "gas": 1554,
        "oil": 0,
        "unknown": 11,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 534,
        "wind": 102,
        "solar": 0,
        "hydro": null,
        "gas": 1503,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 80,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 189,
        "US-SW-WALC": 268
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 114,
        "US-SW-PNM": 0,
        "US-SW-SRP": 186,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 12,
      "renewablePercentage": 10,
      "powerConsumptionTotal": 2376,
      "powerProductionTotal": 2139,
      "powerImportTotal": 537,
      "powerExportTotal": 300,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T02:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:26.188Z",
      "createdAt": "2023-09-27T02:47:34.536Z",
      "powerConsumptionBreakdown": {
        "nuclear": 43,
        "geothermal": 0,
        "biomass": 0,
        "coal": 632,
        "wind": 130,
        "solar": 7,
        "hydro": 65,
        "gas": 1336,
        "oil": 0,
        "unknown": 12,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 743,
        "wind": 63,
        "solar": 0,
        "hydro": null,
        "gas": 1399,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 64,
        "US-SW-PNM": 111,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 207,
        "US-SW-WALC": 248
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 611,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 11,
      "renewablePercentage": 9,
      "powerConsumptionTotal": 2224,
      "powerProductionTotal": 2205,
      "powerImportTotal": 630,
      "powerExportTotal": 611,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T03:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:21.509Z",
      "createdAt": "2023-09-27T03:45:05.660Z",
      "powerConsumptionBreakdown": {
        "nuclear": 30,
        "geothermal": 0,
        "biomass": 0,
        "coal": 594,
        "wind": 102,
        "solar": 3,
        "hydro": 49,
        "gas": 1276,
        "oil": 0,
        "unknown": 7,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 762,
        "wind": 57,
        "solar": 0,
        "hydro": null,
        "gas": 1446,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 130,
        "US-SW-PNM": 66,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 130,
        "US-SW-WALC": 224
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 754,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 9,
      "renewablePercentage": 7,
      "powerConsumptionTotal": 2061,
      "powerProductionTotal": 2265,
      "powerImportTotal": 550,
      "powerExportTotal": 754,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T04:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:21.509Z",
      "createdAt": "2023-09-27T04:47:02.899Z",
      "powerConsumptionBreakdown": {
        "nuclear": 28,
        "geothermal": 0,
        "biomass": 0,
        "coal": 551,
        "wind": 104,
        "solar": 2,
        "hydro": 36,
        "gas": 1209,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 744,
        "wind": 88,
        "solar": 0,
        "hydro": null,
        "gas": 1449,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 124,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 102,
        "US-SW-WALC": 227
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 11,
        "US-SW-SRP": 792,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 9,
      "renewablePercentage": 7,
      "powerConsumptionTotal": 1931,
      "powerProductionTotal": 2281,
      "powerImportTotal": 453,
      "powerExportTotal": 803,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T05:00:00.000Z",
      "updatedAt": "2023-10-01T15:45:26.188Z",
      "createdAt": "2023-09-27T05:47:46.942Z",
      "powerConsumptionBreakdown": {
        "nuclear": 24,
        "geothermal": 0,
        "biomass": 0,
        "coal": 455,
        "wind": 116,
        "solar": 5,
        "hydro": 27,
        "gas": 1161,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 625,
        "wind": 96,
        "solar": 0,
        "hydro": null,
        "gas": 1379,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 226,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 90,
        "US-SW-WALC": 184
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 42,
        "US-SW-SRP": 770,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 10,
      "renewablePercentage": 8,
      "powerConsumptionTotal": 1788,
      "powerProductionTotal": 2100,
      "powerImportTotal": 500,
      "powerExportTotal": 812,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T06:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-27T06:48:00.181Z",
      "powerConsumptionBreakdown": {
        "nuclear": 18,
        "geothermal": 0,
        "biomass": 0,
        "coal": 323,
        "wind": 122,
        "solar": 2,
        "hydro": 21,
        "gas": 1140,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 440,
        "wind": 105,
        "solar": 0,
        "hydro": null,
        "gas": 1331,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 315,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 46,
        "US-SW-WALC": 145
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 57,
        "US-SW-SRP": 699,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 10,
      "renewablePercentage": 9,
      "powerConsumptionTotal": 1626,
      "powerProductionTotal": 1876,
      "powerImportTotal": 506,
      "powerExportTotal": 756,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T07:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-27T07:47:06.273Z",
      "powerConsumptionBreakdown": {
        "nuclear": 15,
        "geothermal": 0,
        "biomass": 0,
        "coal": 255,
        "wind": 138,
        "solar": 1,
        "hydro": 15,
        "gas": 1114,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 363,
        "wind": 110,
        "solar": 0,
        "hydro": null,
        "gas": 1378,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 387,
        "US-SW-PNM": 4,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 13,
        "US-SW-WALC": 168
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 886,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 11,
      "renewablePercentage": 10,
      "powerConsumptionTotal": 1537,
      "powerProductionTotal": 1851,
      "powerImportTotal": 572,
      "powerExportTotal": 886,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T08:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-27T08:46:42.014Z",
      "powerConsumptionBreakdown": {
        "nuclear": 18,
        "geothermal": 0,
        "biomass": 0,
        "coal": 220,
        "wind": 167,
        "solar": 0,
        "hydro": 9,
        "gas": 1071,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 310,
        "wind": 96,
        "solar": 0,
        "hydro": null,
        "gas": 1380,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 391,
        "US-SW-PNM": 105,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 31,
        "US-SW-WALC": 176
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 1003,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 13,
      "renewablePercentage": 12,
      "powerConsumptionTotal": 1486,
      "powerProductionTotal": 1786,
      "powerImportTotal": 703,
      "powerExportTotal": 1003,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T09:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:52.689Z",
      "createdAt": "2023-09-27T09:45:05.136Z",
      "powerConsumptionBreakdown": {
        "nuclear": 19,
        "geothermal": 0,
        "biomass": 0,
        "coal": 217,
        "wind": 157,
        "solar": 0,
        "hydro": 8,
        "gas": 1029,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 309,
        "wind": 109,
        "solar": 0,
        "hydro": null,
        "gas": 1357,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 340,
        "US-SW-PNM": 79,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 21,
        "US-SW-WALC": 188
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 974,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 13,
      "renewablePercentage": 12,
      "powerConsumptionTotal": 1429,
      "powerProductionTotal": 1775,
      "powerImportTotal": 628,
      "powerExportTotal": 974,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T10:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:52.689Z",
      "createdAt": "2023-09-27T10:47:57.350Z",
      "powerConsumptionBreakdown": {
        "nuclear": 19,
        "geothermal": 0,
        "biomass": 0,
        "coal": 216,
        "wind": 138,
        "solar": 0,
        "hydro": 8,
        "gas": 1018,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 306,
        "wind": 131,
        "solar": 0,
        "hydro": null,
        "gas": 1335,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 305,
        "US-SW-PNM": 16,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 215
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 906,
        "US-SW-AZPS": 2,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 12,
      "renewablePercentage": 10,
      "powerConsumptionTotal": 1400,
      "powerProductionTotal": 1772,
      "powerImportTotal": 536,
      "powerExportTotal": 908,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T11:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:52.689Z",
      "createdAt": "2023-09-27T11:45:59.121Z",
      "powerConsumptionBreakdown": {
        "nuclear": 20,
        "geothermal": 0,
        "biomass": 0,
        "coal": 200,
        "wind": 130,
        "solar": 0,
        "hydro": 8,
        "gas": 1018,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 276,
        "wind": 141,
        "solar": 0,
        "hydro": null,
        "gas": 1333,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 299,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 220
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 40,
        "US-SW-SRP": 833,
        "US-SW-AZPS": 19,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 12,
      "renewablePercentage": 10,
      "powerConsumptionTotal": 1377,
      "powerProductionTotal": 1750,
      "powerImportTotal": 519,
      "powerExportTotal": 892,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T12:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-27T12:46:35.549Z",
      "powerConsumptionBreakdown": {
        "nuclear": 21,
        "geothermal": 0,
        "biomass": 0,
        "coal": 222,
        "wind": 116,
        "solar": 0,
        "hydro": 15,
        "gas": 1022,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 313,
        "wind": 104,
        "solar": 0,
        "hydro": null,
        "gas": 1331,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 287,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 229
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 39,
        "US-SW-SRP": 813,
        "US-SW-AZPS": 16,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 11,
      "renewablePercentage": 9,
      "powerConsumptionTotal": 1396,
      "powerProductionTotal": 1748,
      "powerImportTotal": 516,
      "powerExportTotal": 868,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T13:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-27T13:47:22.764Z",
      "powerConsumptionBreakdown": {
        "nuclear": 22,
        "geothermal": 0,
        "biomass": 0,
        "coal": 260,
        "wind": 100,
        "solar": 12,
        "hydro": 20,
        "gas": 995,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 368,
        "wind": 104,
        "solar": 14,
        "hydro": null,
        "gas": 1332,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 92,
        "US-SW-PNM": 8,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 5,
        "US-SW-WALC": 235
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 748,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 11,
      "renewablePercentage": 9,
      "powerConsumptionTotal": 1410,
      "powerProductionTotal": 1818,
      "powerImportTotal": 340,
      "powerExportTotal": 748,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T14:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-27T14:46:43.875Z",
      "powerConsumptionBreakdown": {
        "nuclear": 16,
        "geothermal": 0,
        "biomass": 0,
        "coal": 249,
        "wind": 96,
        "solar": 130,
        "hydro": 13,
        "gas": 913,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 361,
        "wind": 122,
        "solar": 185,
        "hydro": null,
        "gas": 1293,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 18,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 175
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 16,
        "US-SW-SRP": 683,
        "US-SW-AZPS": 37,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 18,
      "renewablePercentage": 17,
      "powerConsumptionTotal": 1418,
      "powerProductionTotal": 1961,
      "powerImportTotal": 193,
      "powerExportTotal": 736,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T15:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-27T15:46:32.781Z",
      "powerConsumptionBreakdown": {
        "nuclear": 20,
        "geothermal": 0,
        "biomass": 0,
        "coal": 238,
        "wind": 108,
        "solar": 214,
        "hydro": 7,
        "gas": 876,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 320,
        "wind": 130,
        "solar": 285,
        "hydro": null,
        "gas": 1161,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 173
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 131,
        "US-SW-PNM": 7,
        "US-SW-SRP": 403,
        "US-SW-AZPS": 65,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 24,
      "renewablePercentage": 22,
      "powerConsumptionTotal": 1463,
      "powerProductionTotal": 1896,
      "powerImportTotal": 173,
      "powerExportTotal": 606,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T16:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-27T16:46:20.261Z",
      "powerConsumptionBreakdown": {
        "nuclear": 58,
        "geothermal": 0,
        "biomass": 0,
        "coal": 262,
        "wind": 143,
        "solar": 297,
        "hydro": 9,
        "gas": 800,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 278,
        "wind": 135,
        "solar": 312,
        "hydro": null,
        "gas": 811,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 5,
        "US-SW-SRP": 74,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 166
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 203,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 7,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 32,
      "renewablePercentage": 29,
      "powerConsumptionTotal": 1571,
      "powerProductionTotal": 1536,
      "powerImportTotal": 245,
      "powerExportTotal": 210,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T17:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-27T17:48:23.203Z",
      "powerConsumptionBreakdown": {
        "nuclear": 132,
        "geothermal": 0,
        "biomass": 0,
        "coal": 265,
        "wind": 176,
        "solar": 313,
        "hydro": 10,
        "gas": 767,
        "oil": 0,
        "unknown": 2,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 270,
        "wind": 150,
        "solar": 307,
        "hydro": null,
        "gas": 736,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 64,
        "US-SW-SRP": 222,
        "US-SW-AZPS": 39,
        "US-SW-WALC": 168
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 292,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 38,
      "renewablePercentage": 30,
      "powerConsumptionTotal": 1664,
      "powerProductionTotal": 1463,
      "powerImportTotal": 493,
      "powerExportTotal": 292,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T18:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-27T18:47:35.663Z",
      "powerConsumptionBreakdown": {
        "nuclear": 32,
        "geothermal": 0,
        "biomass": 0,
        "coal": 251,
        "wind": 178,
        "solar": 305,
        "hydro": 13,
        "gas": 989,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 270,
        "wind": 165,
        "solar": 321,
        "hydro": null,
        "gas": 1059,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 44,
        "US-SW-SRP": 16,
        "US-SW-AZPS": 25,
        "US-SW-WALC": 163
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 294,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 30,
      "renewablePercentage": 28,
      "powerConsumptionTotal": 1769,
      "powerProductionTotal": 1815,
      "powerImportTotal": 248,
      "powerExportTotal": 294,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T19:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-27T19:50:06.331Z",
      "powerConsumptionBreakdown": {
        "nuclear": 20,
        "geothermal": 0,
        "biomass": 0,
        "coal": 248,
        "wind": 180,
        "solar": 285,
        "hydro": 14,
        "gas": 1127,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 282,
        "wind": 177,
        "solar": 319,
        "hydro": null,
        "gas": 1275,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 30,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 180
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 235,
        "US-SW-PNM": 0,
        "US-SW-SRP": 139,
        "US-SW-AZPS": 14,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 27,
      "renewablePercentage": 26,
      "powerConsumptionTotal": 1875,
      "powerProductionTotal": 2053,
      "powerImportTotal": 210,
      "powerExportTotal": 388,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T20:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-27T20:45:25.476Z",
      "powerConsumptionBreakdown": {
        "nuclear": 30,
        "geothermal": 0,
        "biomass": 0,
        "coal": 255,
        "wind": 224,
        "solar": 296,
        "hydro": 16,
        "gas": 1166,
        "oil": 0,
        "unknown": 2,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 269,
        "wind": 180,
        "solar": 305,
        "hydro": null,
        "gas": 1237,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 83,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 39,
        "US-SW-WALC": 193
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 224,
        "US-SW-PNM": 0,
        "US-SW-SRP": 93,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 28,
      "renewablePercentage": 27,
      "powerConsumptionTotal": 1989,
      "powerProductionTotal": 1991,
      "powerImportTotal": 315,
      "powerExportTotal": 317,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T21:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-27T21:46:56.355Z",
      "powerConsumptionBreakdown": {
        "nuclear": 35,
        "geothermal": 0,
        "biomass": 0,
        "coal": 266,
        "wind": 264,
        "solar": 302,
        "hydro": 18,
        "gas": 1189,
        "oil": 0,
        "unknown": 2,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 267,
        "wind": 186,
        "solar": 297,
        "hydro": null,
        "gas": 1209,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 122,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 65,
        "US-SW-WALC": 180
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 202,
        "US-SW-PNM": 0,
        "US-SW-SRP": 49,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 30,
      "renewablePercentage": 28,
      "powerConsumptionTotal": 2075,
      "powerProductionTotal": 1959,
      "powerImportTotal": 367,
      "powerExportTotal": 251,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T22:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-27T22:48:16.318Z",
      "powerConsumptionBreakdown": {
        "nuclear": 41,
        "geothermal": 0,
        "biomass": 0,
        "coal": 277,
        "wind": 274,
        "solar": 286,
        "hydro": 21,
        "gas": 1264,
        "oil": 0,
        "unknown": 2,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 269,
        "wind": 176,
        "solar": 272,
        "hydro": null,
        "gas": 1232,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 146,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 101,
        "US-SW-WALC": 179
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 207,
        "US-SW-PNM": 0,
        "US-SW-SRP": 2,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 29,
      "renewablePercentage": 27,
      "powerConsumptionTotal": 2166,
      "powerProductionTotal": 1949,
      "powerImportTotal": 426,
      "powerExportTotal": 209,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-09-30T23:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-27T23:48:00.809Z",
      "powerConsumptionBreakdown": {
        "nuclear": 41,
        "geothermal": 0,
        "biomass": 0,
        "coal": 282,
        "wind": 239,
        "solar": 239,
        "hydro": 23,
        "gas": 1422,
        "oil": 0,
        "unknown": 2,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 270,
        "wind": 168,
        "solar": 222,
        "hydro": null,
        "gas": 1336,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 99,
        "US-SW-SRP": 1,
        "US-SW-AZPS": 105,
        "US-SW-WALC": 191
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 144,
        "US-SW-PNM": 0,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 24,
      "renewablePercentage": 22,
      "powerConsumptionTotal": 2248,
      "powerProductionTotal": 1996,
      "powerImportTotal": 396,
      "powerExportTotal": 144,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T00:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-28T00:46:39.584Z",
      "powerConsumptionBreakdown": {
        "nuclear": 46,
        "geothermal": 0,
        "biomass": 0,
        "coal": 321,
        "wind": 281,
        "solar": 70,
        "hydro": 39,
        "gas": 1453,
        "oil": 0,
        "unknown": 30,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 305,
        "wind": 160,
        "solar": 63,
        "hydro": null,
        "gas": 1416,
        "oil": null,
        "unknown": 23,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 219,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 187,
        "US-SW-WALC": 192
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 69,
        "US-SW-PNM": 0,
        "US-SW-SRP": 256,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 19,
      "renewablePercentage": 17,
      "powerConsumptionTotal": 2240,
      "powerProductionTotal": 1967,
      "powerImportTotal": 598,
      "powerExportTotal": 325,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T01:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-28T01:47:00.025Z",
      "powerConsumptionBreakdown": {
        "nuclear": 41,
        "geothermal": 0,
        "biomass": 0,
        "coal": 368,
        "wind": 271,
        "solar": 5,
        "hydro": 41,
        "gas": 1396,
        "oil": 0,
        "unknown": 26,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 363,
        "wind": 148,
        "solar": 0,
        "hydro": null,
        "gas": 1418,
        "oil": null,
        "unknown": 14,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 12,
        "US-SW-PNM": 255,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 184,
        "US-SW-WALC": 189
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 434,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 17,
      "renewablePercentage": 15,
      "powerConsumptionTotal": 2149,
      "powerProductionTotal": 1943,
      "powerImportTotal": 640,
      "powerExportTotal": 434,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T02:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-28T02:47:32.302Z",
      "powerConsumptionBreakdown": {
        "nuclear": 36,
        "geothermal": 0,
        "biomass": 0,
        "coal": 431,
        "wind": 203,
        "solar": 5,
        "hydro": 41,
        "gas": 1328,
        "oil": 0,
        "unknown": 20,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 498,
        "wind": 108,
        "solar": 0,
        "hydro": null,
        "gas": 1421,
        "oil": null,
        "unknown": 8,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 124,
        "US-SW-PNM": 189,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 172,
        "US-SW-WALC": 188
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 645,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 14,
      "renewablePercentage": 12,
      "powerConsumptionTotal": 2063,
      "powerProductionTotal": 2035,
      "powerImportTotal": 673,
      "powerExportTotal": 645,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T03:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-28T03:44:06.471Z",
      "powerConsumptionBreakdown": {
        "nuclear": 36,
        "geothermal": 0,
        "biomass": 0,
        "coal": 496,
        "wind": 177,
        "solar": 2,
        "hydro": 34,
        "gas": 1199,
        "oil": 0,
        "unknown": 15,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 605,
        "wind": 104,
        "solar": 0,
        "hydro": null,
        "gas": 1359,
        "oil": null,
        "unknown": 10,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 46,
        "US-SW-PNM": 153,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 165,
        "US-SW-WALC": 179
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 662,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 13,
      "renewablePercentage": 11,
      "powerConsumptionTotal": 1959,
      "powerProductionTotal": 2078,
      "powerImportTotal": 543,
      "powerExportTotal": 662,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T04:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:52.689Z",
      "createdAt": "2023-09-28T04:45:10.500Z",
      "powerConsumptionBreakdown": {
        "nuclear": 31,
        "geothermal": 0,
        "biomass": 0,
        "coal": 473,
        "wind": 203,
        "solar": 0,
        "hydro": 20,
        "gas": 1106,
        "oil": 0,
        "unknown": 5,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 634,
        "wind": 137,
        "solar": 0,
        "hydro": null,
        "gas": 1354,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 137,
        "US-SW-PNM": 161,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 161,
        "US-SW-WALC": 127
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 873,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 14,
      "renewablePercentage": 12,
      "powerConsumptionTotal": 1838,
      "powerProductionTotal": 2125,
      "powerImportTotal": 586,
      "powerExportTotal": 873,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T05:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-28T05:48:30.590Z",
      "powerConsumptionBreakdown": {
        "nuclear": 21,
        "geothermal": 0,
        "biomass": 0,
        "coal": 426,
        "wind": 171,
        "solar": 1,
        "hydro": 21,
        "gas": 1085,
        "oil": 0,
        "unknown": 3,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 585,
        "wind": 122,
        "solar": 0,
        "hydro": null,
        "gas": 1346,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 187,
        "US-SW-PNM": 102,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 92,
        "US-SW-WALC": 124
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 831,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 12,
      "renewablePercentage": 11,
      "powerConsumptionTotal": 1727,
      "powerProductionTotal": 2053,
      "powerImportTotal": 505,
      "powerExportTotal": 831,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T06:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-28T06:45:34.039Z",
      "powerConsumptionBreakdown": {
        "nuclear": 25,
        "geothermal": 0,
        "biomass": 0,
        "coal": 350,
        "wind": 183,
        "solar": 0,
        "hydro": 18,
        "gas": 1044,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 488,
        "wind": 117,
        "solar": 0,
        "hydro": null,
        "gas": 1339,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 189,
        "US-SW-PNM": 152,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 109,
        "US-SW-WALC": 124
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 896,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 14,
      "renewablePercentage": 12,
      "powerConsumptionTotal": 1622,
      "powerProductionTotal": 1944,
      "powerImportTotal": 574,
      "powerExportTotal": 896,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T07:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-28T07:46:31.849Z",
      "powerConsumptionBreakdown": {
        "nuclear": 27,
        "geothermal": 0,
        "biomass": 0,
        "coal": 425,
        "wind": 209,
        "solar": 1,
        "hydro": 12,
        "gas": 1072,
        "oil": 0,
        "unknown": 1,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 593,
        "wind": 129,
        "solar": 0,
        "hydro": null,
        "gas": 1347,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {
        "US-SW-EPE": 189,
        "US-SW-PNM": 152,
        "US-SW-SRP": 0,
        "US-SW-AZPS": 109,
        "US-SW-WALC": 124
      },
      "powerExportBreakdown": {
        "US-SW-EPE": 0,
        "US-SW-PNM": 0,
        "US-SW-SRP": 896,
        "US-SW-AZPS": 0,
        "US-SW-WALC": 0
      },
      "fossilFreePercentage": 14,
      "renewablePercentage": 13,
      "powerConsumptionTotal": 1747,
      "powerProductionTotal": 2069,
      "powerImportTotal": 574,
      "powerExportTotal": 896,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T08:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-28T08:47:51.994Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 562,
        "wind": 137,
        "solar": 0,
        "hydro": 0,
        "gas": 1336,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 562,
        "wind": 137,
        "solar": 0,
        "hydro": null,
        "gas": 1336,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 7,
      "renewablePercentage": 7,
      "powerConsumptionTotal": 2035,
      "powerProductionTotal": 2035,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T09:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-28T09:44:59.004Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 453,
        "wind": 127,
        "solar": 0,
        "hydro": 0,
        "gas": 1328,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 453,
        "wind": 127,
        "solar": 0,
        "hydro": null,
        "gas": 1328,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 7,
      "renewablePercentage": 7,
      "powerConsumptionTotal": 1908,
      "powerProductionTotal": 1908,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T10:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-28T10:46:09.959Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 456,
        "wind": 130,
        "solar": 0,
        "hydro": 0,
        "gas": 1331,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 456,
        "wind": 130,
        "solar": 0,
        "hydro": null,
        "gas": 1331,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 7,
      "renewablePercentage": 7,
      "powerConsumptionTotal": 1917,
      "powerProductionTotal": 1917,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T11:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-28T11:47:36.373Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 407,
        "wind": 126,
        "solar": 0,
        "hydro": 0,
        "gas": 1328,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 407,
        "wind": 126,
        "solar": 0,
        "hydro": null,
        "gas": 1328,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 7,
      "renewablePercentage": 7,
      "powerConsumptionTotal": 1861,
      "powerProductionTotal": 1861,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T12:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-28T12:48:20.568Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 408,
        "wind": 122,
        "solar": 0,
        "hydro": 0,
        "gas": 1322,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 408,
        "wind": 122,
        "solar": 0,
        "hydro": null,
        "gas": 1322,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 7,
      "renewablePercentage": 7,
      "powerConsumptionTotal": 1852,
      "powerProductionTotal": 1852,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T13:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-28T13:47:26.537Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 374,
        "wind": 126,
        "solar": 13,
        "hydro": 0,
        "gas": 1091,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 374,
        "wind": 126,
        "solar": 13,
        "hydro": null,
        "gas": 1091,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 9,
      "renewablePercentage": 9,
      "powerConsumptionTotal": 1604,
      "powerProductionTotal": 1604,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T14:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-28T14:48:02.773Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 315,
        "wind": 134,
        "solar": 176,
        "hydro": 0,
        "gas": 677,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 315,
        "wind": 134,
        "solar": 176,
        "hydro": null,
        "gas": 677,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 24,
      "renewablePercentage": 24,
      "powerConsumptionTotal": 1302,
      "powerProductionTotal": 1302,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T15:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-28T15:43:58.223Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 276,
        "wind": 106,
        "solar": 220,
        "hydro": 0,
        "gas": 411,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 276,
        "wind": 106,
        "solar": 220,
        "hydro": null,
        "gas": 411,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 32,
      "renewablePercentage": 32,
      "powerConsumptionTotal": 1013,
      "powerProductionTotal": 1013,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T16:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-28T16:47:09.390Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 205,
        "wind": 129,
        "solar": 261,
        "hydro": 0,
        "gas": 374,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 205,
        "wind": 129,
        "solar": 261,
        "hydro": null,
        "gas": 374,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 40,
      "renewablePercentage": 40,
      "powerConsumptionTotal": 969,
      "powerProductionTotal": 969,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T17:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-28T17:47:44.855Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 180,
        "wind": 152,
        "solar": 290,
        "hydro": 0,
        "gas": 403,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 180,
        "wind": 152,
        "solar": 290,
        "hydro": null,
        "gas": 403,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 43,
      "renewablePercentage": 43,
      "powerConsumptionTotal": 1025,
      "powerProductionTotal": 1025,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T18:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-28T18:46:10.371Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 180,
        "wind": 157,
        "solar": 331,
        "hydro": 0,
        "gas": 440,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 180,
        "wind": 157,
        "solar": 331,
        "hydro": null,
        "gas": 440,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 44,
      "renewablePercentage": 44,
      "powerConsumptionTotal": 1108,
      "powerProductionTotal": 1108,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T19:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-28T19:46:33.719Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 178,
        "wind": 171,
        "solar": 311,
        "hydro": 0,
        "gas": 553,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 178,
        "wind": 171,
        "solar": 311,
        "hydro": null,
        "gas": 553,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 40,
      "renewablePercentage": 40,
      "powerConsumptionTotal": 1213,
      "powerProductionTotal": 1213,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T20:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:52.689Z",
      "createdAt": "2023-09-28T20:46:27.446Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 178,
        "wind": 191,
        "solar": 277,
        "hydro": 0,
        "gas": 601,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 178,
        "wind": 191,
        "solar": 277,
        "hydro": null,
        "gas": 601,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 38,
      "renewablePercentage": 38,
      "powerConsumptionTotal": 1247,
      "powerProductionTotal": 1247,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T21:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-28T21:46:26.270Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 181,
        "wind": 190,
        "solar": 263,
        "hydro": 0,
        "gas": 728,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 181,
        "wind": 190,
        "solar": 263,
        "hydro": null,
        "gas": 728,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 33,
      "renewablePercentage": 33,
      "powerConsumptionTotal": 1362,
      "powerProductionTotal": 1362,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T22:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-28T22:48:19.619Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 185,
        "wind": 192,
        "solar": 263,
        "hydro": 0,
        "gas": 858,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 185,
        "wind": 192,
        "solar": 263,
        "hydro": null,
        "gas": 858,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 30,
      "renewablePercentage": 30,
      "powerConsumptionTotal": 1498,
      "powerProductionTotal": 1498,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-01T23:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-28T23:46:03.796Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 180,
        "wind": 198,
        "solar": 226,
        "hydro": 0,
        "gas": 991,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 180,
        "wind": 198,
        "solar": 226,
        "hydro": null,
        "gas": 991,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 27,
      "renewablePercentage": 27,
      "powerConsumptionTotal": 1595,
      "powerProductionTotal": 1595,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T00:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-29T00:45:58.106Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 219,
        "wind": 195,
        "solar": 82,
        "hydro": 0,
        "gas": 1468,
        "oil": 0,
        "unknown": 30,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 219,
        "wind": 195,
        "solar": 82,
        "hydro": null,
        "gas": 1468,
        "oil": null,
        "unknown": 30,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 14,
      "renewablePercentage": 14,
      "powerConsumptionTotal": 1994,
      "powerProductionTotal": 1994,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T01:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:52.689Z",
      "createdAt": "2023-09-29T01:45:10.781Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 271,
        "wind": 187,
        "solar": 0,
        "hydro": 0,
        "gas": 1517,
        "oil": 0,
        "unknown": 10,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 271,
        "wind": 187,
        "solar": 0,
        "hydro": null,
        "gas": 1517,
        "oil": null,
        "unknown": 10,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 9,
      "renewablePercentage": 9,
      "powerConsumptionTotal": 1985,
      "powerProductionTotal": 1985,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T02:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-29T02:46:59.117Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 363,
        "wind": 145,
        "solar": 0,
        "hydro": 0,
        "gas": 1475,
        "oil": 0,
        "unknown": 13,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 363,
        "wind": 145,
        "solar": 0,
        "hydro": null,
        "gas": 1475,
        "oil": null,
        "unknown": 13,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 7,
      "renewablePercentage": 7,
      "powerConsumptionTotal": 1996,
      "powerProductionTotal": 1996,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T03:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:52.689Z",
      "createdAt": "2023-09-29T03:46:21.471Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 400,
        "wind": 128,
        "solar": 0,
        "hydro": 0,
        "gas": 1443,
        "oil": 0,
        "unknown": 5,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 400,
        "wind": 128,
        "solar": 0,
        "hydro": null,
        "gas": 1443,
        "oil": null,
        "unknown": 5,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 6,
      "renewablePercentage": 6,
      "powerConsumptionTotal": 1976,
      "powerProductionTotal": 1976,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T04:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-29T04:46:29.775Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 402,
        "wind": 113,
        "solar": 0,
        "hydro": 0,
        "gas": 1507,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 402,
        "wind": 113,
        "solar": 0,
        "hydro": null,
        "gas": 1507,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 6,
      "renewablePercentage": 6,
      "powerConsumptionTotal": 2022,
      "powerProductionTotal": 2022,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T05:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:17.013Z",
      "createdAt": "2023-09-29T05:44:52.434Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 415,
        "wind": 96,
        "solar": 0,
        "hydro": 0,
        "gas": 1492,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 415,
        "wind": 96,
        "solar": 0,
        "hydro": null,
        "gas": 1492,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 5,
      "renewablePercentage": 5,
      "powerConsumptionTotal": 2003,
      "powerProductionTotal": 2003,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T06:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-29T06:47:46.596Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 405,
        "wind": 102,
        "solar": 0,
        "hydro": 0,
        "gas": 1405,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 405,
        "wind": 102,
        "solar": 0,
        "hydro": null,
        "gas": 1405,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 5,
      "renewablePercentage": 5,
      "powerConsumptionTotal": 1912,
      "powerProductionTotal": 1912,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": false,
      "estimationMethod": null
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T07:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-29T07:46:23.257Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 405,
        "wind": 102,
        "solar": 0,
        "hydro": 0,
        "gas": 1405,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 405,
        "wind": 102,
        "solar": 0,
        "hydro": null,
        "gas": 1405,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 5,
      "renewablePercentage": 5,
      "powerConsumptionTotal": 1912,
      "powerProductionTotal": 1912,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T08:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-29T08:46:43.453Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 388,
        "wind": 97,
        "solar": 0,
        "hydro": 0,
        "gas": 1381,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 388,
        "wind": 97,
        "solar": 0,
        "hydro": null,
        "gas": 1381,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 5,
      "renewablePercentage": 5,
      "powerConsumptionTotal": 1866,
      "powerProductionTotal": 1866,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T09:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-29T09:47:05.390Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 373,
        "wind": 90,
        "solar": 0,
        "hydro": 0,
        "gas": 1364,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 373,
        "wind": 90,
        "solar": 0,
        "hydro": null,
        "gas": 1364,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 5,
      "renewablePercentage": 5,
      "powerConsumptionTotal": 1828,
      "powerProductionTotal": 1828,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T10:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-29T10:46:40.770Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 367,
        "wind": 84,
        "solar": 0,
        "hydro": 0,
        "gas": 1360,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 367,
        "wind": 84,
        "solar": 0,
        "hydro": null,
        "gas": 1360,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 5,
      "renewablePercentage": 5,
      "powerConsumptionTotal": 1811,
      "powerProductionTotal": 1811,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T11:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-29T11:44:49.459Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 370,
        "wind": 79,
        "solar": 0,
        "hydro": 0,
        "gas": 1370,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 370,
        "wind": 79,
        "solar": 0,
        "hydro": null,
        "gas": 1370,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 4,
      "renewablePercentage": 4,
      "powerConsumptionTotal": 1819,
      "powerProductionTotal": 1819,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T12:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:52.689Z",
      "createdAt": "2023-09-29T12:45:29.618Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 380,
        "wind": 73,
        "solar": 0,
        "hydro": 0,
        "gas": 1391,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 380,
        "wind": 73,
        "solar": 0,
        "hydro": null,
        "gas": 1391,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 4,
      "renewablePercentage": 4,
      "powerConsumptionTotal": 1844,
      "powerProductionTotal": 1844,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T13:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-29T13:44:49.378Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 381,
        "wind": 64,
        "solar": 56,
        "hydro": 0,
        "gas": 1377,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 381,
        "wind": 64,
        "solar": 56,
        "hydro": null,
        "gas": 1377,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 6,
      "renewablePercentage": 6,
      "powerConsumptionTotal": 1879,
      "powerProductionTotal": 1879,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T14:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-29T14:47:48.389Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 369,
        "wind": 53,
        "solar": 174,
        "hydro": 0,
        "gas": 1319,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 369,
        "wind": 53,
        "solar": 174,
        "hydro": null,
        "gas": 1319,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 12,
      "renewablePercentage": 12,
      "powerConsumptionTotal": 1915,
      "powerProductionTotal": 1915,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T15:00:00.000Z",
      "updatedAt": "2023-10-02T16:45:52.689Z",
      "createdAt": "2023-09-29T15:46:05.323Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 348,
        "wind": 50,
        "solar": 263,
        "hydro": 0,
        "gas": 1224,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 348,
        "wind": 50,
        "solar": 263,
        "hydro": null,
        "gas": 1224,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 17,
      "renewablePercentage": 17,
      "powerConsumptionTotal": 1885,
      "powerProductionTotal": 1885,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T16:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:10.394Z",
      "createdAt": "2023-09-29T16:49:37.536Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 340,
        "wind": 50,
        "solar": 303,
        "hydro": 0,
        "gas": 1188,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 340,
        "wind": 50,
        "solar": 303,
        "hydro": null,
        "gas": 1188,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 19,
      "renewablePercentage": 19,
      "powerConsumptionTotal": 1881,
      "powerProductionTotal": 1881,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T17:00:00.000Z",
      "updatedAt": "2023-10-02T16:46:03.622Z",
      "createdAt": "2023-09-29T17:43:22.849Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 336,
        "wind": 52,
        "solar": 314,
        "hydro": 0,
        "gas": 1181,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 336,
        "wind": 52,
        "solar": 314,
        "hydro": null,
        "gas": 1181,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 19,
      "renewablePercentage": 19,
      "powerConsumptionTotal": 1884,
      "powerProductionTotal": 1884,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    },
    {
      "zone": "US-SW-TEPC",
      "datetime": "2023-10-02T18:00:00.000Z",
      "updatedAt": "2023-10-02T17:47:18.996Z",
      "createdAt": "2023-09-29T18:46:03.926Z",
      "powerConsumptionBreakdown": {
        "nuclear": 0,
        "geothermal": 0,
        "biomass": 0,
        "coal": 337,
        "wind": 59,
        "solar": 314,
        "hydro": 0,
        "gas": 1193,
        "oil": 0,
        "unknown": 0,
        "hydro discharge": 0,
        "battery discharge": 0
      },
      "powerProductionBreakdown": {
        "nuclear": null,
        "geothermal": null,
        "biomass": null,
        "coal": 337,
        "wind": 59,
        "solar": 314,
        "hydro": null,
        "gas": 1193,
        "oil": null,
        "unknown": 0,
        "hydro discharge": null,
        "battery discharge": null
      },
      "powerImportBreakdown": {},
      "powerExportBreakdown": {},
      "fossilFreePercentage": 20,
      "renewablePercentage": 20,
      "powerConsumptionTotal": 1903,
      "powerProductionTotal": 1903,
      "powerImportTotal": null,
      "powerExportTotal": null,
      "isEstimated": true,
      "estimationMethod": "TIME_SLICER_AVERAGE"
    }
  ]
};


const useFakeEMaps = ({ currentHour }: { currentHour: Date }): ReturnType<typeof useEMaps> => {
  const data = {
    ...SOME_PAST_RANGE_WE_SAW_ONE_TIME,
    data: SOME_PAST_RANGE_WE_SAW_ONE_TIME.data.map((point, i) => {
      const ni = (SOME_PAST_RANGE_WE_SAW_ONE_TIME.data.length - 1) - i;
      const newHour = new Date(currentHour);
      newHour.setUTCHours(newHour.getUTCHours() - ni);
      return {
        ...point,
        datetime: newHour.toISOString(),
      };
    }),
  };

  log('EMaps fake results:', {
    loading: false,
    error: null,
    pastRange: data,
  });

  return {
    loading: false,
    error: null,
    pastRange: data,
  };
};

export default useFakeEMaps;
