import GoogleAnalytics from 'react-ga4';


const trackers: Parameters<typeof GoogleAnalytics.initialize>[0] = [];
switch (import.meta.env.VITE_RUBY_MODE) {
  case 'production':
    trackers.push({
      trackingId: 'G-5FC2SXYH09',
      gaOptions: {
        name: 'Bellawatt Tracker',
      },
    });
    break;
  default:
}

const GaTracker = {
  initialize: () => {
    if (trackers.length) {
      GoogleAnalytics.initialize(trackers);
    }
  },
  trackEvent: (event: Parameters<typeof GoogleAnalytics.event>[0], options = {}) => {
    trackers.forEach((tracker) => {
      if (options.constructor === Object && Object.keys(options).length > 0) {
        GoogleAnalytics.set({
          ...options,
        });
      }
      GoogleAnalytics.event(event, [tracker.gaOptions.name]);
    });
  },
};

export default GaTracker;
