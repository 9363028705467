
const DIRECTIONAL_COEFFICIENTS = [
  {
    directionMinimum: 0,
    constant: 34.500,
    xs: [25, 1, -0.5],
  },
  {
    directionMinimum: 60,
    constant: 52.500,
    xs: [1, 0.23, -0.005],
  },
  {
    directionMinimum: 120,
    constant: 26.500,
    xs: [0.1, 0.05, 0.008],
  },
  {
    directionMinimum: 180,
    constant: 52.500,
    xs: [9, 0.1, 0],
  },
  {
    directionMinimum: 240,
    constant: 40.667,
    xs: [5, 0.7, -0.031],
  },
  {
    directionMinimum: 300,
    constant: 31.533,
    xs: [30, -2, 0],
  },
];

const WIND_MAX = 260;

const getDirectionalCoefficients = (direction: number) => {
  return DIRECTIONAL_COEFFICIENTS.find(({ directionMinimum }) => {
    return direction >= directionMinimum && direction < directionMinimum + 60
  }) || DIRECTIONAL_COEFFICIENTS[0]; // fallback to anything
};

const forecastWindGeneration = (speed: number, direction: number) => {
  // We carefully modeled the historical data
  // and built this algorithm here:
  // https://docs.google.com/spreadsheets/d/1SkgzcFKFLWCtfOU_5LYDLBVwuriTJ0ZPErafl4bWMeI/edit#gid=1264478937
  // Explained in detail here:
  // https://docs.google.com/document/d/1gcUCwhOJOJdLSSVCfeRG7nNL-mNeONmeGWlL3lCWjp4/edit

  // % 360 yields 0 <= direction < 360
  const { constant, xs } = getDirectionalCoefficients(direction % 360);
  const windGen = xs[0] * speed
    + xs[1] * Math.pow(speed, 2)
    + xs[2] * Math.pow(speed, 3)
    + constant;
  return Math.max(Math.min(windGen, WIND_MAX), 0);
};

export default forecastWindGeneration;
