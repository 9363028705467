import React, { ReactNode } from 'react';
import debug from '../debug';
import { useTime } from "./TimeProvider";
import useEMaps from "../hooks/useEMaps";
import useFakeEMaps from "../hooks/useFakeEMaps";
import useOpenMeteo from "../hooks/useOpenMeteo";
import useFakeOpenMeteo from "../hooks/useFakeOpenMeteo";
import calculateEnergyOutput from '../utils/calculateEnergyOutput';

type EnergyOutputContextType = {
  loading: boolean,
  error: typeof Error | null,
} & Partial<ReturnType<typeof calculateEnergyOutput>>;

const EnergyOutputContext = React.createContext<EnergyOutputContextType>({
  loading: true,
  error: null,
  realTime: undefined,
  highThreshold: undefined,
  lowThreshold: undefined,
  forecastOutput: undefined,
});

export default function EnergyOutputProvider({ children }: { children: ReactNode }) {
  const { time } = useTime();

  const currentHour = new Date(time);
  currentHour.setUTCMinutes(0);
  currentHour.setUTCSeconds(0);
  currentHour.setUTCMilliseconds(0);

  const {
    loading: emapsLoading,
    error: emapsError,
    pastRange,
  } = debug.fake ? useFakeEMaps({ currentHour }) : useEMaps({ currentHour });

  const {
    loading: openMeteoLoading,
    error: openMeteoError,
    forecast,
  } = debug.fake ? useFakeOpenMeteo() : useOpenMeteo();

  const {
    realTime,
    highThreshold,
    lowThreshold,
    forecastOutput,
  } = pastRange && forecast
    ? calculateEnergyOutput({
      emapsPastRange: pastRange,
      openMeteoForecast: forecast,
      currentHour,
    })
    : {
      realTime: undefined,
      highThreshold: undefined,
      lowThreshold: undefined,
      forecastOutput: undefined,
    };

  return (
    <EnergyOutputContext.Provider value={{
      loading: emapsLoading || openMeteoLoading,
      error: emapsError || openMeteoError,
      realTime,
      highThreshold,
      lowThreshold,
      forecastOutput,
    }}>
      {children}
    </EnergyOutputContext.Provider>
  );
}

export const useEnergyOutput = () => React.useContext(EnergyOutputContext);
