import { useState, useEffect } from 'react';
import makeGetHttp from '../utils/getHttp';

export type OpenMeteoForecast = {
  latitude: number;
  longitude: number;
  generationtime_ms: number;
  utc_offset_seconds: number;
  timezone: string;
  timezone_abbreviation: string;
  elevation: number;
  hourly_units: {
      time: string;
      windspeed_10m: string;
      winddirection_10m: string;
      direct_radiation: string;
  };
  hourly: {
    time: string[];
    windspeed_10m: number[];
    winddirection_10m: number[];
    direct_radiation: number[];
  };
};

// https://api.open-meteo.com/v1/forecast?latitude=32.2&longitude=-103.5&hourly=windspeed_10m,winddirection_10m,direct_radiation&forecast_days=14

const get = makeGetHttp('https://api.open-meteo.com', {});

const useOpenMeteo = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<typeof Error | null>(null);
  const [forecastJson, setForecastJson] = useState<OpenMeteoForecast>();

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (error) {
      setError(null);
    }

    get<OpenMeteoForecast>('/v1/forecast', {
      latitude: '32.2',
      longitude: '-103.5',
      hourly: 'windspeed_10m,winddirection_10m,direct_radiation',
      forecast_days: '14',
    })
      .then((json) => setForecastJson(json))
      .catch((error) => {
        setError(error);
        console?.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  return {
    loading,
    error,
    forecast: forecastJson,
  };
};

export default useOpenMeteo;
